import { HttpServiceV1 } from '@data/common/services'

import {
  OKXOrderSide,
  OKXOrderType,
  transformSideFromOKX,
  transformSideToOKX,
  transformStatusFromOKX,
  transformTypeFromOKX,
  transformTypeToOKX
} from '@data/stocks/okx'

import { OrderSource, OrderType } from '@domain/stocks/order'

import { OrderRepository } from '../../_'

import type { ICreateOrderPayload, IOKXOrder, IUpdateOrderPayload } from '../interfaces'

import type {
  ICancelOrderPort,
  ICreateOrderPort,
  IGetOpenOrderListPort,
  IOrderDTO,
  IOrderRepository,
  IUpdateOrderPort
} from '@domain/stocks/order'

import type { IPairDTO } from '@domain/stocks/pair'

class OKXOrderRepository extends OrderRepository implements IOrderRepository {

  public override async getOpenOrderList (port: IGetOpenOrderListPort): Promise<IOrderDTO[]> {
    return HttpServiceV1.get<IOKXOrder[] | null>(`/${this.provider}/list`)
      .then((response) => {
        if (response === null) return []

        return this._transformOrderList(response, port.list)
      })
  }

  public override async createOrder (port: ICreateOrderPort): Promise<boolean> {
    const payload: ICreateOrderPayload = {
      symbol: port.pair.ticker.slash.split('/').join('-'),
      tradeMod: 'cash',
      side: transformSideToOKX(port.side),
      orderType: transformTypeToOKX(port.type),
      quantity: port.amount,
      price: port.price
    }

    if (payload.orderType === OKXOrderType.MARKET && payload.side === OKXOrderSide.BUY) {
      payload.quantity = (Number(payload.quantity) * Number(payload.price)).toString()
    }

    this._transformOrderPayload(payload)

    return payload.orderType === OKXOrderType.STOP_LIMIT
      ? this._createAlgoOrder(payload)
      : this._createClassicOrder(payload)
  }

  public override async updateOrder (port: IUpdateOrderPort): Promise<boolean> {
    const payloads: IUpdateOrderPayload[] = port.orders.map((item) => {
      return {
        ordId: item.orderId,
        symbol: item.pair.ticker.slash.split('/').join('-'),
        tradeMod: 'cash',
        side: transformSideToOKX(item.side),
        orderType: transformTypeToOKX(item.type),
        quantity: item.amount,
        price: item.price
      }
    })

    this._transformOrderPayload(payloads)

    const classicOrdersPayloads = payloads.filter((item) => item.orderType !== OKXOrderType.STOP_LIMIT)
    const algoOrdersPayloads = payloads.filter((item) => item.orderType === OKXOrderType.STOP_LIMIT)

    if (classicOrdersPayloads.length !== 0) {
      const promises = []

      for (const payload of classicOrdersPayloads) {
        promises.push(this._updateClassicOrder(payload))
      }

      return Promise.allSettled(promises).then(() => true)
    }

    if (algoOrdersPayloads.length !== 0) {
      const promises = []

      for (const payload of algoOrdersPayloads) {
        promises.push(this._updateAlgoOrder(payload))
      }

      return Promise.allSettled(promises).then(() => true)
    }

    return Promise.resolve(true)
  }

  public async cancelOrder (port: ICancelOrderPort): Promise<boolean> {
    return port.type === OrderType.STOP_LIMIT
      ? this._cancelAlgoOrder(port)
      : this._cancelClassicOrder(port)
  }

  private async _createClassicOrder (payload: ICreateOrderPayload): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/create`, { body: payload })
      .then(() => true)
  }

  private async _createAlgoOrder (payload: ICreateOrderPayload): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/create-algo`, { body: payload })
      .then(() => true)
  }

  private async _updateClassicOrder (payload: IUpdateOrderPayload): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/update`, { body: payload })
      .then(() => true)
  }

  private async _updateAlgoOrder (payload: IUpdateOrderPayload): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/update-algo`, { body: payload })
      .then(() => true)
  }

  private async _cancelClassicOrder (port: ICancelOrderPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/cancel`, {
      body: {
        instId: port.pair.ticker.slash.split('/').join('-'),
        ordId: port.id
      }
    })
      .then(() => true)
  }

  private async _cancelAlgoOrder (port: ICancelOrderPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/cancel-algo`, {
      body: {
        instId: port.pair.ticker.slash.split('/').join('-'),
        algoId: port.id
      }
    })
      .then(() => true)
  }

  private _transformOrderList (payload: IOKXOrder[], list: IPairDTO[]): IOrderDTO[] {
    const orders: IOrderDTO[] = []

    payload.forEach((item) => {
      const symbol = item.Symbol.split('-').join('')
      const pair = list.find((_pair) => _pair.ticker.symbol === symbol)

      if (pair) {
        orders.push({
          id: item.OrderID,
          clientOrderId: '',
          dealId: 0,
          pair: pair,
          createdDate: Number(item.UpdateTime),
          updatedDate: Number(item.UpdateTime),
          side: transformSideFromOKX(item.Side),
          source: OrderSource.ORIGIN,
          type: transformTypeFromOKX(item.Type),
          status: transformStatusFromOKX(item.Status),
          amount: item.OrigQuantity,
          executedAmount: item.ExecutedQuantity,
          price: item.Price,
          stopPrice: item.StopPrice,
          tradeType: 'SPOT',
          completePercent: (Number(item.ExecutedQuantity) / Number(item.OrigQuantity) * 100).toString(),
          takeProfit: { isInclude: false, percent: '1.00' },
          stopLoss: { isInclude: false, percent: '3.00' }
        })
      }
    })

    return orders.sort((a, b) => a.createdDate - b.createdDate)
  }

  private _transformOrderPayload (payload: ICreateOrderPayload | IUpdateOrderPayload[]): void {
    if (Array.isArray(payload)) {
      payload.forEach((item) => {
        this._deleteFromOrderPayload(item)
      })

      return
    }

    this._deleteFromOrderPayload(payload)
  }

  private _deleteFromOrderPayload (payload: ICreateOrderPayload): void {
    if (payload.orderType === OKXOrderType.MARKET) {
      delete payload.price
    }
  }

}

export { OKXOrderRepository }
